li,
a {
    cursor: pointer;
}


@media screen {
    .wrapper {
        margin: 20px 20px 20px 20px;
        width: 96%;
        height: 100%;
    
        background: #fff;
        position: relative;
        box-sizing: border-box;
        padding: 20px;
        border: 20px groove green;        

    }
}
@media print {
    .wrapper {
        margin: 10px 20px 20px 20px;
        width: 96%;
        height: 1350px;
    
        background: #fff;
        position: relative;
        box-sizing: border-box;
        padding: 20px;
        /* border: 20px groove green; */
    }
}

.cHeader {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;

}

.logo {
    width: 10%;
}

.companyName {
    width: 90%;
    line-height: 0;
}

.logo1 {
    width: 15%;
    margin-top: 30px;
    line-height: 0;
}

.companyName h1 {
    color: rgb(9, 75, 9);
    font-size: 40px;
    text-align: center;
}

.companyName p {
    color: green;
    font-size: 20px;
    line-height: 1;
    position: relative;
    text-align: center;
    overflow: hidden;
}

/* .companyName p::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    left: -20%;
    height: 2px;
    background: green;
}

.companyName p::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    right: -20%;
    height: 2px;
    background: green;
} */

.myTable {
    width: 100%;
}

.myTable table {
    width: 100%;
    border: 1.5px solid black;
    border-collapse: collapse;
    /* border: 1px solid green; */
}

.myTable tr td {
    border: 1.5px solid black;
    padding: 2px !important;
    font-size: 14px;
}

.myTable .half tr td {
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.myTable .half-other tr td {

    font-size: 14px;
   font-weight: bold;
   font-family: Arial, Helvetica, sans-serif;
}

.myTable th {
    border: 1.5px solid black;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.myTable tr,
td,
th {
    border: 1px solid #ddd;
}

/* .myTable tr:nth-child(even){
background: rgb(251, 255, 251);
} */
.result {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.measure {
    width: 90%;

}

.measure h1 {
    font-size: 20px;

}

.measure p {
    font-weight: 400;
    color: black;
}

.signature {
    height: 100px;

}



.cFooter {
    width: 100%;
    text-align: center;
    color: rgb(0, 97, 224);
    /* position: absolute; */
    bottom: 0;

}

.issueDate {
    font-size: 13px;
}

.cFooter p {
    font-size: 10px;
}

.stamp1 {
    background-image: url("../images/stamp1.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.stamp2 {
    background-image: url("../images/stamp2.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.resultTable {
    position: relative;
    bottom: 50px;
    left: 0;
}

p {
    text-align: justify;
}